import React from "react";
import { motion } from "framer-motion";
import { Tilt } from "react-tilt";

import { fadeIn } from "../utils/motion";

type CardCourseProps = {
  imagePath: string;
  name: string;
  description: string;
  index: number
};

const CardCourse: React.FC<CardCourseProps> = ({
  imagePath,
  name,
  description,
  index
}) => {
  return (
    <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
      <Tilt
        options={{
          max: 45,
          scale: 1,
          speed: 450,
        }}
        className="bg-terciary p-5 rounded-2xl sm:w-[400px] w-full"
      >
        <div className="cursor-pointer relative w-full h-[230px] flex flex-grow">
          <img
            src={`${process.env.REACT_APP_BACKEND_BASE_URL}/${imagePath}`}
            alt="project_image"
            className="w-full h-full object-cover rounded-2xl"
          />

          {/* <div className="absolute inset-0 flex justify-end m-3 card-img_hover">
          <div
            className="black-gradient w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
          >
            <img
              src={github}
              alt="source code"
              className="w-1/2 h-1/2 object-contain"
            />
          </div>
        </div> */}
        </div>

        <div className="cursor-pointer mt-5">
          <h3 className="text-white font-bold text-center text-[20px]">{name}</h3>
          <p className="mt-2 text-secondary text-center text-[14px]">{description}</p>
        </div>
      </Tilt>
    </motion.div>
  );
};

export default CardCourse;

import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { BannerVideo, CardCourse } from "../components";

import { styles } from "../styles";
import { Course } from "../interfaces/course.interface";
import { fadeIn, staggerContainer, textVariant } from "../utils/motion";
import { BackendClient } from "../utils/backendClient";

const Home = () => {
  const client = new BackendClient();

  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    const getCourses = async () => {
      try {
        const { data } = await client.get("/courses");
        setCourses(data);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full mx-auto">
      <BannerVideo />

      <motion.section
        variants={staggerContainer()}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`text-white max-w-[90rem] p-10 mx-auto ${styles.paddingX} flex flex-col items-start gap-5`}
      >
        <motion.div className="mt-10 w-full" variants={textVariant()}>
          <h1 className={styles.sectionHeadText}>Aprende a Programar</h1>
        </motion.div>

        <motion.div
          variants={fadeIn("left", "", 0.3, 1)}
          className="mt-4 text-secondary text-[17px] w-full flex justify-start leading-[30px]"
        >
          <p className="text-left max-w-3xl">
            Soy un programador fullstack con mas de 4 años de experiencia en el
            sector tencológico, en el cuál, la mitad de ese tiempo he trabajado
            en el sector financiero y de seguros, para entidades como el{" "}
            <b>Santander</b> y el <b>BBVA</b> como programador. He creado esta
            plataforma con la finalidad de compartir el conocimiento que he
            adquirido a lo largo de mis años como programador.
          </p>
        </motion.div>

        <motion.div
          variants={fadeIn("left", "", 0.3, 1)}
          className="mt-4 w-full flex justify-start text-secondary text-[17px] leading-[30px]"
        >
          <p className="text-left max-w-3xl">
            Aquí aprenderás, desde los conceptos más básicos de la programación,
            hasta el uso de frameworks más avanzados como react o angular, entre
            otras muchas cosas.
          </p>
        </motion.div>

        <motion.div
          variants={fadeIn("left", "", 0.3, 1)}
          className="mt-5 h-[60px] flex items-center text-secondary max-w-3xl leading-[30px]"
        >
          <a
            href="/registro"
            className="bg-terciary py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary"
          >
            Empecemos
          </a>
        </motion.div>
      </motion.section>

      <motion.section
        variants={staggerContainer()}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`text-white max-w-[90rem] p-10 mx-auto ${styles.paddingX} flex flex-col items-start gap-5`}
      >
        <motion.div className="mt-10 w-full" variants={textVariant()}>
          <h1 className={`${styles.sectionHeadText} text-right`}>
            Explora los cursos
          </h1>
        </motion.div>

        <motion.div
          variants={fadeIn("right", "", 0.3, 1)}
          className="mt-4 w-full text-secondary text-[17px] leading-[30px] flex justify-end"
        >
          <p className="max-w-3xl text-right">
            ¿Estás listo para aprender algo nuevo? En nuestra sección de
            "Explora los Cursos", encontrarás una variedad de oportunidades
            educativas diseñadas para expandir tus conocimientos y habilidades.
            Desde principiantes hasta expertos, con contenidos actualizados, cada
            curso está diseñado para proporcionarte una experiencia de
            aprendizaje enriquecedora y práctica. ¡Descubre tu próxima pasión,
            mejora tus competencias o prepárate para el siguiente paso en tu
            carrera!
          </p>
        </motion.div>

        <motion.div
          variants={fadeIn("left", "", 0.3, 1)}
          className="mt-5 h-[60px] justify-end flex items-center text-secondary w-full leading-[30px]"
        >
          <a
            href="/cursos"
            className="bg-terciary py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary"
          >
            Explora todos los cursos
          </a>
        </motion.div>

        <div className="mt-20 flex justify-end flex-wrap gap-7 w-full">
          {courses.map((course, index) => (
            <CardCourse
              imagePath={course.imagePath}
              description={course.description}
              name={course.name}
              index={index}
              key={course._id}
            />
          ))}
        </div>
      </motion.section>
    </div>
  );
};

export default Home;

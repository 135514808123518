import axios from "axios";
import queryString from "query-string";

interface RequestOptions {
  params?: object;
  data?: object;
  headers?: {
    "Content-Type"?: string;
    Authorization?: string;
  };
}

class BackendClient {
  backend_url = process.env.REACT_APP_BACKEND_URL || "http://localhost:4000/api";
  options: RequestOptions = {};

  constructor() {
    this.options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      this.options.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
  }

  get(service: any, query = {}) {
    return axios.get(`${this.backend_url}${service}`, {
      ...this.options,
      params: query,
    });
  }

  post(service: any, data = {}) {
    return axios.post(`${this.backend_url}${service}`, data, this.options);
  }

  put(service: any, data = {}) {
    return axios.put(`${this.backend_url}${service}`, data, this.options);
  }

  patch(service: any, query = {}, data = {}) {
    return axios.patch(
      `${this.backend_url}${service}?${queryString.stringify(query, {
        arrayFormat: "bracket",
      })}`,
      data,
      this.options
    );
  }

  delete(service: any, query = {}) {
    return axios.delete(
      `${this.backend_url}${service}?${queryString.stringify(query, {
        arrayFormat: "bracket",
      })}`,
      this.options
    );
  }
}

export { BackendClient };

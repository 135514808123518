import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BackendClient } from "../utils/backendClient";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const ConfirmAccount = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const client = new BackendClient();

  const [error, setError] = useState(false);

  useEffect(() => {
    const confirmAccount = async () => {
      try {
        setError(false);
        const { data } = await client.get(`/users/confirm-user/${token}`);
        toast.success(data.msg);
        setTimeout(() => {
          navigate("/login");
        }, 4000);
      } catch (error: any) {
        setError(true);
        if (axios.isAxiosError(error)) {
          if (error.response) toast.error(error.response.data.msg);
          else if (error.request)
            toast.error(
              "No se recibió respuesta del servidor. Por favor, inténtalo más tarde."
            );
          else toast.error("Ocurrió un error inesperado.");
        } else toast.error("Ocurrió un error inesperado.");
      }
    };
    confirmAccount();
  }, []);

  return (
    <div className="w-full h-screen flex items-center justify-center flex-col text-center">
      <div className="p-5">
        <h2 className="text-6xl font-bold">Confirmación de Cuenta</h2>
      </div>
      <div className="p-3">
        {error ? (
          <>
            <p className="font-medium">
              Ha ocurrido un error al intentar confirmar tu cuenta.
            </p>
            <p className="font-medium">
              Si el error persiste, contacte con un administrador.
            </p>
            <Button className="mt-4" size="large">
              <Link to="/login">Volver</Link>
            </Button>
          </>
        ) : (
          <>
            <p className="font-medium">
              Tu cuenta ha sido confirmada, se te redigirá automaticamente al
              login.
            </p>
            <p className="font-medium">
              En caso de que no se te redireccione automaticamente, puedes
              pulsar{" "}
              <Link className="text-blue-600 font-bold" to="/login">
                aqui
              </Link>
              .
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmAccount;

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { MainLayout, ProtectedLayout } from "./Layouts";
import {
  Login,
  Settings,
  Register,
  Suscription,
  ConfirmAccount,
  Courses,
  Home,
  About,
  Contact,
} from "./pages";

import { Provider } from "./context";

const App = () => {
  return (
    <Provider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registro" element={<Register />} />
            <Route path="/cursos" element={<Courses />} />
            <Route path="/acerca-de" element={<About />} />
            <Route path="/contacto" element={<Contact />} />
          </Route>

          <Route path="confirm-user/:token" element={<ConfirmAccount />} />

          <Route path="/dashboard" element={<ProtectedLayout />}>
            <Route path="/dashboard/settings" element={<Settings />} />
            <Route path="/dashboard/suscription" element={<Suscription />} />
          </Route>
        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="dark"
      />
    </Provider>
  );
};

export default App;

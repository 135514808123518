import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Context } from "../context";
import { NavBar } from "../components";

const ProtectedLayout = () => {
  const {
    state: { user },
    dispatch,
  } = useContext(Context);

  return (
    <>
      {user !== null ? (
        <>
          <NavBar />
          <div>
            <Outlet />
          </div>
        </>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default ProtectedLayout;

import { Nav } from "../interfaces/core.interface";

const navLinks: Nav[] = [
  {
    id: "home",
    title: "Inicio",
    route: "/"
  },
  {
    id: "courses",
    title: "Cursos",
    route: "/cursos"
  },
  {
    id: "contact",
    title: "Contacto",
    route: "/contacto"
  },
  {
    id: "about",
    title: "Acerca de",
    route: "/acerca-de"
  }
];

export { navLinks };

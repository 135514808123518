const BannerVideo = () => {
  return (
    <div className="w-full h-screen">
      <video
        src={process.env.PUBLIC_URL + "/assets/video.mp4"}
        loop
        autoPlay
        muted
        className="object-cover absolute h-screen w-screen -z-10 top-0 left-0"
      ></video>
    </div>
  );
};

export default BannerVideo;

import { Outlet } from "react-router-dom";
import { NavBar } from "../components";
import { Home } from "../pages";

const MainLayout = () => {
  return (
    <>
      <div className="bg-primary relative z-0">
        <div className="bg-hero-pattern bg-cover bg-no-repeat bg-center">
          <NavBar />
        </div>
        <div>
          <Outlet/>
        </div>
      </div>
    </>
  );
};

export default MainLayout;

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

import { styles } from "../styles";
import { fadeIn, staggerContainer, textVariant } from "../utils/motion";
import { BackendClient } from "../utils/backendClient";

const Courses = () => {
  return (
    <div className="w-full mx-auto">
      <motion.section
        variants={staggerContainer()}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className={`text-white max-w-[90rem] p-10 mx-auto ${styles.paddingX} flex flex-col items-start gap-5`}
      ></motion.section>
    </div>
  );
};

export default Courses;
